[dir] .card-strategic {
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.07);
  border-radius: 0.428rem;
  border: 1px solid #f3f3f3;
}
.btn-trash {
  height: 44px;
}
[dir] .btn-trash {
  text-align: center;
  padding: 0px !important;
}
[dir] .table-edit {
  background-color: #e5e5e5;
  border-radius: 15px;
}
[dir] .table-edit .form-control {
  background: #fff !important;
}
[dir] .table-edit input:disabled {
  background: #efefef !important;
  cursor: not-allowed;
}